<template>
  <RightSideBar
    v-if="openModal"
    submit="Add"
    @submit="add"
    @close="$emit('close')"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Add App(s)
      </p>
    </template>
    <template v-slot:subtitle>
      <p class="font-semibold pb-3 text-darkPurple">
        Grant access to selected apps.
      </p>
    </template>
    <div>
      <div class="w-full pl-3 flex justify-between items-center gap-3 text-darkPurple">
        <p>Apps</p>
        <div class="flex justify-start items-center gap-2 m-right">
          <p>Select All</p>
          <checkbox
            v-model="selectAll"
            checkbox-size="height:16px; width:16px"
          />
        </div>
      </div>
      <scroll-container height="500px">
        <div
          v-for="(item, index) in modules"
          :key="index"
          class="flex flex-col px-3 mt-4 container "
          :class="item.selected ? 'opacity-50 bg-cultured ' : ''"
        >
          <div class="flex mt-3 items-center">
            <custom-image :slug="item.slug" class="mr-3" />
            <div class="flex flex-col flex-grow">
              <p class="font-semibold">
                {{ item.name }}
              </p>
            </div>
            <div>
              <checkbox
                v-model="item.hasPrivilege"
                checkbox-size="height:16px; width:16px"
              />
            </div>
          </div>
        </div>
      </scroll-container>
    </div>
  </RightSideBar>
</template>

<script>
import RightSideBar from "@/components/RightSideBar";
import CustomImage from "@/components/CustomImage";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";

export default {
  components: { RightSideBar, CustomImage, Checkbox, ScrollContainer },

  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modulesData: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedModules: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      selectAll: false,
      modules: []
    };
  },
 
  watch: {
    openModal(){
      // Created a Map from selectedModules with name as the key and hasPrivilege as the value
      const selected = new Map(this.selectedModules.map(selectedModule => [selectedModule.name, selectedModule.hasPrivilege]));

      const modules = this.modules.map(module => ({
        ...module,
        hasPrivilege: selected.has(module.name) ? selected.get(module.name) : false
      }));
      this.modules = modules;
    },
    modulesData(){
      this.modules = JSON.parse(JSON.stringify(this.modulesData))
    },
    selectAll(newValue){
      this.$emit("updateModules", newValue);
    },
  },
  methods: {
    add() {
      const selectedModules = this.modules.filter(module => module.hasPrivilege);
      this.$emit("selectedModules", selectedModules);
      this.selectAll = false;
    }
  },
  mounted() {}
};
</script>

<style scoped>
.container {
  height: 64px;
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 5px;
}
.m-right{
  margin-right: 19.5px;
}
</style>